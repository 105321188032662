import type { OperationVariables } from '@apollo/client'

import type {
  SubscriptionsQuery,
  SubscriptionStatus,
} from '~/@types/generated/backend/graphql-schema-types'

type Parameters = {
  limit: number
  status: SubscriptionStatus[]
}

const updateQuery = (
  previousResult: SubscriptionsQuery,
  {
    fetchMoreResult,
    variables,
  }: { fetchMoreResult?: SubscriptionsQuery; variables?: OperationVariables },
): SubscriptionsQuery => {
  if (!fetchMoreResult) return previousResult
  const { count, subscriptions } = fetchMoreResult.subscriptions

  if (
    variables?.limit === previousResult.subscriptions.subscriptions.length &&
    variables.offset === 0
  ) {
    return fetchMoreResult
  }

  return {
    subscriptions: {
      ...previousResult.subscriptions,
      count,
      subscriptions: [
        ...previousResult.subscriptions.subscriptions,
        ...subscriptions,
      ],
    },
  }
}

export const useLazySubscriptionOrders = ({ limit, status }: Parameters) =>
  useFetchMoreWrapper<ReturnType<typeof useSubscriptionsLazyQuery>>(
    () =>
      useSubscriptionsLazyQuery(
        {
          filter: { status },
          limit,
          offset: 0,
        },
        { fetchPolicy: 'cache-and-network' },
      ),
    updateQuery,
    (result) => result?.subscriptions.subscriptions.length,
    (result) => result?.subscriptions.count,
    'subscriptions.error',
  )
