<template>
  <MyOrdersTab v-bind="{ fetchActive, fetchPast, visible }" type="subscription">
    <template #no-results>
      <MyOrdersNoResults variant="subscription" />
    </template>

    <template #active="{ result }">
      <li
        v-for="subscription in getSubscriptions(result)"
        :key="subscription.code"
      >
        <MyOrdersSubscriptionCard
          v-bind="subscription"
          :date="subscription.details.nextDrawDate!"
        />
      </li>
    </template>

    <template #past="{ result }">
      <li
        v-for="subscription in getSubscriptions(result)"
        :key="subscription.code"
      >
        <MyOrdersSubscriptionCard
          v-bind="subscription"
          :date="subscription.details.lastDrawDate"
        >
          <template #header>
            <MyOrdersStatusBadgeSubscription :status="subscription.status" />
          </template>
        </MyOrdersSubscriptionCard>
      </li>
    </template>
  </MyOrdersTab>
</template>

<script lang="ts" setup>
import {
  type SubscriptionsQuery,
  SubscriptionStatus,
} from '~/@types/generated/backend/graphql-schema-types'
import type { MyOrdersTabImplementationProps } from '~/features/MyOrders/types'

const ACTIVE_STATUS = [SubscriptionStatus.Active]

const PAST_STATUS = [SubscriptionStatus.Cancelled]

defineProps<MyOrdersTabImplementationProps>()

const fetchActive = () =>
  useLazySubscriptionOrders({
    limit: 20,
    status: ACTIVE_STATUS,
  })

const fetchPast = () =>
  useLazySubscriptionOrders({
    limit: 3,
    status: PAST_STATUS,
  })

const getSubscriptions = (result: SubscriptionsQuery) =>
  result.subscriptions.subscriptions
</script>
